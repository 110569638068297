<template>
    <div class="set-old-material">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">
                        Пожалуйста, уточните что именно устарело
                    </h3>
                    <button
                        type="button"
                        class="btn-block-option close-modal"
                        @click="$modal.hide('add-comment')"
                    >
                        ×
                    </button>
                </div>
                <div class="block-content content">
                    <ValidationObserver
                        tag="form"
                        @submit.prevent="onSubmit"
                        ref="observer"
                        class="set-old-material__form"
                    >
                        <div class="set-old-material__form-block">
                            <ValidationProvider
                                vid="text"
                                rules="required"
                                v-slot="provider"
                            >
                                <div
                                    class="form-group"
                                    v-bind:class="{
                                        'is-invalid': provider.errors.length > 0
                                    }"
                                >
                                    <label
                                        class="sr-only"
                                        for="idea-description"
                                        >Описание устаревшего материала</label
                                    >
                                    <div
                                        class="set-old-material__form-textarea-wrapper"
                                    >
                                        <textarea
                                            class="set-old-material__form-textarea form-control"
                                            id="idea-description"
                                            v-model="form.text"
                                            placeholder="Введите описание устаревшего материала"
                                        ></textarea>
                                    </div>
                                    <div
                                        id="idea-description-error"
                                        class="invalid-feedback animated fadeInDown"
                                        v-if="provider.errors.length > 0"
                                    >
                                        {{ provider.errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>

                            <vue-progress-bar></vue-progress-bar>
                        </div>
                        <div class="set-old-material__form-footer">
                            <button
                                class="v-btn set-old-material__form-submit-btn"
                                type="submit"
                            >
                                Отправить
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import session from '@/api/session'

export default {
    name: 'AddCommentModal',
    data () {
        return {
            materialId: this.$route.params.material_id,
            form: {
                text: ''
            }
        }
    },
    props: ['material'],
    methods: {
        async onSubmit () {
            const isValidModal = await this.$refs.observer.validate()
            if (isValidModal) {
                try {
                    this.$Progress.start()
                    await session.post(
                        `/api/v1/material/${this.materialId}/set_old/`,
                        {
                            text: this.form.text
                        }
                    )
                    this.material.suggestion++
                    this.$modal.hide('add-comment')
                    this.$swal({
                        title: 'Комментарий добавлен!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    this.$Progress.finish()
                } catch (err) {
                    if (err.response) {
                        this.$refs.observer.setErrors(err.response.data)
                    } else {
                        this.$swal({
                            title: 'Ошибка!',
                            text:
                                'При обработке запроса произошла ошибка на сервере',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400
                        })
                    }
                    this.$Progress.finish()
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.v--modal-overlay .v--modal-box {
    overflow: visible;
}

.modal-dialog {
    max-width: 840px;
    margin: 0;

    @include below($tablet) {
        max-width: none;
    }
}

.modal-content {
    padding: 30px;

    @include below($mobile) {
        padding: 15px;
    }
}

.modal-header {
    padding: 0;
    margin-bottom: 35px;

    @include below($mobile) {
        margin-bottom: 15px;
    }
}

.block-content {
    padding: 0;
}

.set-old-material {
    &__form-textarea-wrapper {
        position: relative;
        min-height: 128px;
        border: 1px solid $border-gray;
        border-radius: 6px;
        transition: border-color 0.3s;

        .is-invalid & {
            border-color: $error-text;
        }
    }
    &__form-textarea {
        width: 100%;
        min-height: 128px;
        padding: 14px;
        border: none;

        resize: none;

        @include below($mobile) {
            min-height: 200px;
        }

        &::placeholder {
            color: $text-gray;
        }

        &:focus {
            outline: none;
        }
    }
    &__form-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    &__form-submit-btn {
        margin-right: 24px;

        @include below($mobile) {
            margin-bottom: 15px;
        }
    }
}
</style>
